<div class="dylan-web" [style.backgroundColor]="animations.backgroundColor">
    <img srcset="
        /assets/images/background/background.png 1x,
        /assets/images/background/background@2x.png 2x
    " alt="" class="background-image" @backgroundPicture [style.opacity]="animations.backgroundImageOpacity">
    <app-menu [open]="menuOpen" (closed)="menuOpen = false"></app-menu>
    <div class="top-container" [style.color]="animations.navigationItemsColor">
        <svg-icon src="/assets/images/menu.svg" alt="menu" class="clickable-text menu-button-container" svgClass="menu-button" [svgStyle]="{ fill: animations.navigationItemsColor }" (click)="menuOpen = true"></svg-icon>
        <div class="top-middle-container">
            <span class="name">
                Dylan Tasat
            </span>
        </div>
        <div class="languages">
            <span class="language clickable-text" *ngFor="let language of availableLanguages" (click)="setLanguage(language.value)" [ngClass]="{ active: getCurrentLanguage() === language.value }">
                {{ language.name }}
            </span>
        </div>
    </div>
    <div class="center-container">
        <div class="left-container">
            <div class="social-networks">
                <a *ngFor="let socialNetwork of socialNetworks" [href]="socialNetwork.link" target="_blank" class="social-network-link">
                    <svg-icon src="/assets/images/social-networks/{{ socialNetwork.name }}.svg" class="social-network-container" svgClass="social-network" [svgStyle]="{ fill: animations.navigationItemsColor }"></svg-icon>
                </a>
            </div>
        </div>
        <div class="page-content">
            <app-home [active]="currentPage === 'home'" [animations]="animations"></app-home>
            <app-about-me [active]="currentPage === 'about-me'" [animations]="animations"></app-about-me>
            <app-projects [active]="currentPage === 'projects'" [animations]="animations"></app-projects>
            <app-contact [active]="currentPage === 'contact'" [animations]="animations"></app-contact>
        </div>
        <div class="right-container">
            <div class="steps">
                <div class="step" [ngClass]="{ active: this.currentPage === 'home' }"></div>
                <div class="step" [ngClass]="{ active: this.currentPage === 'about-me' }"></div>
                <div class="step" [ngClass]="{ active: this.currentPage === 'projects' }"></div>
                <div class="step" [ngClass]="{ active: this.currentPage === 'contact' }"></div>
            </div>
        </div>
    </div>
    <div class="bottom-container" [style.color]="animations.navigationItemsColor">
        <span class="last-update" translate>
            LAST_UPDATE
        </span>
        <div class="scroll-tip-container" [style.opacity]="animations.scrollButtonOpacity">
            <span class="scroll-tip" translate>
                SCROLL_TIP
            </span>
            <img src="/assets/images/down-arrow.png" alt="" class="scroll-tip-arrow">
        </div>
        <div class="empty"></div>
    </div>
</div>
