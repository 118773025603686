import { quotes, projects } from './../constants';
import { Animations } from './../models/animations.model';
import { Color } from './../models/project.model';
import { UtilsService } from './utils.service';
import { Injectable, ElementRef } from '@angular/core';

interface AnimationStateConfig {
    duration: number;
}

function intermediateValue(start: number, end: number, percent: number): number {
    return start + percent * (end - start);
}

function intermediateColor(start: Color, end: Color, percent: number): Color {
    const red = start.r + percent * (end.r - start.r);
    const green = start.g + percent * (end.g - start.g);
    const blue = start.b + percent * (end.b - start.b);
    return new Color(red, green, blue);
}

function trimPercent(percent: number, start: number, end: number) {
    if (percent < start)
        return 0;
    if (percent > end)
        return 1;
    return (percent - start) / (end - start);
}

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {
    characteristicsElement: ElementRef<HTMLElement>;
    timelineElement: ElementRef<HTMLElement>;
    projectsOverviewElement: ElementRef<HTMLElement>;
    projectsOverviewContainerElement: ElementRef<HTMLElement>;

    animationStatesDefinitions: { [key: string]: AnimationStateConfig };

    currentPage = 'home';
    currentProject = 0;

    constructor(
        private utilsService: UtilsService
    ) {
        const step = 200; // Esto es una estimación del step que tendría la ruedita del mouse, no pude hacer una forma de que se calcule automático ya que depende del mouse (de hecho en los teléfonos no hay step).
        this.animationStatesDefinitions = {
            home: { duration: 0.5 * step },
            homeToAboutTransition: { duration: 5.5 * step },
            about: { duration: 34 * step },
            aboutToProjectsTransition: { duration: 6 * step },
            projectsIntro: { duration: 4 * step },
            projects: { duration: utilsService.isMobile() ? 2 * step * (projects.length - 1) : 1 * step * (projects.length - 1) },
            projectsToContactTransition: { duration: 6 * step },
            contactIntro: { duration: 6 * step },
            contact: { duration: 5 * step }
        };
    }

    getAnimations(scroll: number) {
        let currentMin = 0;
        for (const stateName of Object.keys(this.animationStatesDefinitions)) {
            const stateConfig = this.animationStatesDefinitions[stateName];
            if (scroll >= currentMin && scroll < currentMin + stateConfig.duration) {
                let percent = 0;
                if (scroll !== 0) {
                    percent = (scroll - currentMin) / (stateConfig.duration);
                }
                return this.getAnimationsForState(stateName, percent);
            }
            currentMin += stateConfig.duration;
        }
        return {};
    }

    getBackgroundImageMaxOpacity() {
        return this.utilsService.isMobile() ? .4 : 1;
    }

    getProjectTransition(percent: number) {
        if (!this.projectsOverviewElement || !this.projectsOverviewContainerElement) return 0;
        const projectsHeight = this.projectsOverviewElement.nativeElement.clientHeight - 30;
        const projectWidth = this.projectsOverviewElement.nativeElement.clientWidth / projects.length;
        const projectHeight = projectsHeight / projects.length;
        const containerHeight = this.projectsOverviewContainerElement.nativeElement.clientHeight;

        if (this.utilsService.isMobile()) {
            return intermediateValue(
                containerHeight / 2 - projectHeight / 2,
                - projectsHeight + projectHeight + containerHeight / 2 - projectHeight / 2,
                percent
            );
            // Version de a pasos: return - (projectHeight) * (this.currentProject) + (containerHeight / 2 - projectHeight / 2);
        } else {
            return - projectWidth * this.currentProject;
        }
    }

    getCurrentProject(percent: number) {
        if (this.utilsService.isMobile()) {
            return Math.floor((projects.length - 1) * percent + 0.5);
        } else {
            return Math.floor((projects.length - 1) * percent);
        }
    }

    getAnimationsForState(stateName: string, percent: number): Animations {
        this.currentProject = 0;
        if (stateName === 'home') {
            this.currentPage = 'home';
            return {
                titleFirstLineTranslation: 0,
                titleSecondLineTranslation: 0,
                titleThirdLineTranslation: 0,
                descriptionTranslation: 0,
                navigationItemsColor: 'rgb(255,255,255)',
                scrollButtonOpacity: 1,
                backgroundColor: 'rgb(14, 14, 14)',
                backgroundImageOpacity: this.getBackgroundImageMaxOpacity(),
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: 0,
                projectsTranslation: this.getProjectTransition(0),
                projectsOpacity: 0,
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'homeToAboutTransition') {
            this.currentPage = 'home';
            return {
                titleFirstLineTranslation: intermediateValue(0, -380, percent),
                titleSecondLineTranslation: intermediateValue(0, -320, trimPercent(percent, .2, 1)),
                titleThirdLineTranslation: intermediateValue(0, -260, trimPercent(percent, .4, 1)),
                descriptionTranslation: intermediateValue(0, -200, trimPercent(percent, .6, 1)),
                navigationItemsColor: intermediateColor(new Color(255, 255, 255), new Color(13, 13, 13), percent).toString(),
                scrollButtonOpacity: intermediateValue(1, 0, percent),
                backgroundColor: intermediateColor(new Color(14, 14, 14), new Color(255, 255, 255), trimPercent(percent, .5, 1)).toString(),
                backgroundImageOpacity: intermediateValue(this.getBackgroundImageMaxOpacity(), 0, trimPercent(percent, 0, .5)),
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: 0,
                projectsTranslation: this.getProjectTransition(0),
                projectsOpacity: 0,
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'about') {
            this.currentPage = 'about-me';
            const quotesTranslations = [];
            const quotesOpacities = [];
            for (let i = 0; i < quotes.length; i++) {
                const customPercent = trimPercent(percent, i * (1 / quotes.length), (i + 1) * (1 / quotes.length));
                const element = document.getElementById(`quote-${i}`);
                quotesTranslations.push(intermediateValue(element.clientHeight + 300, -300, customPercent));
                if (customPercent < .5)
                    quotesOpacities.push(intermediateValue(0, 1, trimPercent(customPercent, 0, .5)));
                if (customPercent >= .5 && customPercent < .85)
                    quotesOpacities.push(1);
                if (customPercent >= .85)
                    quotesOpacities.push(intermediateValue(1, 0, trimPercent(customPercent, .5, 1)));
            }
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(13,13,13)',
                scrollButtonOpacity: 0,
                backgroundColor: 'rgb(255, 255, 255)',
                backgroundImageOpacity: 0,
                aboutTitleTranslation: intermediateValue(-300, 0, trimPercent(percent, 0, .2)),
                characteristicsTranslation: intermediateValue(0, - this.characteristicsElement.nativeElement.clientWidth - window.innerWidth, percent),
                quotesTranslations,
                quotesOpacities,
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: 0,
                projectsTranslation: this.getProjectTransition(0),
                projectsOpacity: 0,
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'aboutToProjectsTransition') {
            this.currentPage = 'about-me';
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: intermediateColor(new Color(13, 13, 13), new Color(255, 255, 255), percent).toString(),
                scrollButtonOpacity: 0,
                backgroundColor: intermediateColor(new Color(255, 255, 255), new Color(13, 13, 13), percent).toString(),
                backgroundImageOpacity: 0,
                aboutTitleTranslation: intermediateValue(0, -300, trimPercent(percent, 0, .5)),
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: 0,
                projectsTranslation: this.getProjectTransition(0),
                projectsOpacity: 0,
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'projectsIntro') {
            this.currentPage = 'projects';
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(255, 255, 255)',
                scrollButtonOpacity: 0,
                backgroundColor: 'rgb(13,13,13)',
                backgroundImageOpacity: 0,
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: intermediateValue(-300, 0, percent),
                timelineOpacity: intermediateValue(0, 1, percent),
                timelineTranslation: 0,
                projectsTranslation: this.getProjectTransition(0),
                projectsOpacity: intermediateValue(0, 1, percent),
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'projects') {
            this.currentPage = 'projects';
            this.currentProject = this.getCurrentProject(percent);
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(255, 255, 255)',
                scrollButtonOpacity: 0,
                backgroundColor: 'rgb(13,13,13)',
                backgroundImageOpacity: 0,
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: 0,
                timelineOpacity: 1,
                timelineTranslation: intermediateValue(0, -this.timelineElement.nativeElement.clientHeight + 25, percent),
                projectsTranslation: this.getProjectTransition(percent),
                projectsOpacity: 1,
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'projectsToContactTransition') {
            this.currentPage = 'projects';
            this.currentProject = projects.length - 1;
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(255, 255, 255)',
                scrollButtonOpacity: 0,
                backgroundColor: intermediateColor(new Color(13, 13, 13), new Color(46, 46, 46), percent).toString(),
                backgroundImageOpacity: 0,
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: intermediateValue(0, -300, percent),
                timelineOpacity: intermediateValue(1, 0, percent),
                timelineTranslation: -this.timelineElement.nativeElement.clientHeight + 25,
                projectsTranslation: this.getProjectTransition(1),
                projectsOpacity: intermediateValue(1, 0, percent),
                contactTitleTranslation: -850,
                contactDescriptionTranslation: -850,
                contactButtonTranslation: -850,
                contactDataTranslation: -850,
                contactImageTranslation: 850,
                contactShadowOpening: 0
            };
        } else if (stateName === 'contactIntro') {
            this.currentPage = 'contact';
            this.currentProject = projects.length - 1;
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(255, 255, 255)',
                scrollButtonOpacity: 0,
                backgroundColor: intermediateColor(new Color(13, 13, 13), new Color(46, 46, 46), percent).toString(),
                backgroundImageOpacity: 0,
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: -this.timelineElement.nativeElement.clientHeight + 25,
                projectsTranslation: this.getProjectTransition(1),
                projectsOpacity: 0,
                contactTitleTranslation: intermediateValue(-200, 0, trimPercent(percent, 0, .4)),
                contactDescriptionTranslation: intermediateValue(-260, 0, trimPercent(percent, 0, .6)),
                contactButtonTranslation: intermediateValue(-320, 0, trimPercent(percent, 0, .8)),
                contactDataTranslation: intermediateValue(-380, 0, trimPercent(percent, 0, 1)),
                contactImageTranslation: intermediateValue(200, 0, trimPercent(percent, 0, .5)),
                contactShadowOpening: intermediateValue(0, this.utilsService.isMobile() ? 5.0 : 10.0, trimPercent(percent, .5, 1))
            };
        } else if (stateName === 'contact') {
            this.currentPage = 'contact';
            this.currentProject = projects.length - 1;
            return {
                titleFirstLineTranslation: -380,
                titleSecondLineTranslation: -320,
                titleThirdLineTranslation: -260,
                descriptionTranslation: -200,
                navigationItemsColor: 'rgb(255, 255, 255)',
                scrollButtonOpacity: 0,
                backgroundColor: 'rgb(46, 46, 46)',
                backgroundImageOpacity: 0,
                aboutTitleTranslation: -300,
                characteristicsTranslation: 0,
                quotesTranslations: [0, 0, 0, 0],
                quotesOpacities: [0, 0, 0, 0],
                projectsTitleTranslation: -300,
                timelineOpacity: 0,
                timelineTranslation: -this.timelineElement.nativeElement.clientHeight + 25,
                projectsTranslation: this.getProjectTransition(1),
                projectsOpacity: 0,
                contactTitleTranslation: 0,
                contactDescriptionTranslation: 0,
                contactButtonTranslation: 0,
                contactDataTranslation: 0,
                contactImageTranslation: 0,
                contactShadowOpening: this.utilsService.isMobile() ? 5.0 : 10.0
            };
        }
        return {};
    }
}
