import { Animations } from './../../models/animations.model';
import { Component, OnInit, Input } from '@angular/core';
import { trigger, query, transition, style, animate, stagger } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
      trigger('title', [
          transition(':enter', [
              query('.first-line, .second-line, .third-line', [
                  style({ opacity: 0, transform: 'translateX(-200%)' }),
                  stagger('.3s', [
                      animate('1s', style({ opacity: 1, transform: 'none' }))
                  ]),
              ])
          ])
      ]),
      trigger('description', [
          transition(':enter', [
              style({ opacity: 0, transform: 'translateX(-200%)' }),
              animate('1s 1s', style({ opacity: 1, transform: 'none' }))
          ])
      ])
  ]
})
export class HomeComponent implements OnInit {

    @Input()
    active: boolean;

    @Input()
    animations: Animations;

    constructor() { }

    ngOnInit(): void {
    }

}
