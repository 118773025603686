
<div class="about-me" [ngClass]="{ 'active-page': active }">
    <h1 class="about-me-title" translate style.transform="translateY({{animations.aboutTitleTranslation}}%)">
        ABOUT_ME_TITLE
    </h1>
    <div class="characteristics" translate style.transform="translateX({{animations.characteristicsTranslation}}px)" #characeristics>
        CHARACTERISTICS
    </div>
    <div class="quotes">
        <app-quote
            *ngFor="let quote of quotes; let i = index"
            [translation]="animations.quotesTranslations[i]"
            [style.opacity]="animations.quotesOpacities[i]"
            [quote]="quote"
            [index]="i"
        >
        </app-quote>
    </div>
</div>
