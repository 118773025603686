import { Quote } from './../../../models/quote.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

    @Input()
    quote: Quote;

    @Input()
    translation: number;

    @Input()
    opacity: number;

    @Input()
    index: number;

    constructor() { }

    ngOnInit(): void {
    }

}
