
<div class="contact" [ngClass]="{ 'active-page': active }">
    <div class="contact-view">
        <h1 class="title" translate style.transform="translateX({{animations.contactTitleTranslation}}%)">
            CONTACT.TITLE
        </h1>
        <p class="description" translate style.transform="translateX({{animations.contactDescriptionTranslation}}%)">
            CONTACT.DESCRIPTION
        </p>
        <form class="contact-button-form" action="https://wa.me/541130602703" style.transform="translateX({{animations.contactButtonTranslation}}%)">
            <input type="submit" class="contact-button" value="{{ 'CONTACT.BUTTON' | translate }}"/>
        </form>
        <div class="contact-data" style.transform="translateX({{animations.contactDataTranslation}}%)">
            <div class="contact-data-item">
                <span class="label" translate>
                    CONTACT.TALK
                </span>
                <a class="value" href="tel:+541130602703">
                    +54 11 3060 2703
                </a>
            </div>
            <div class="contact-data-item">
                <span class="label" translate>
                    CONTACT.WRITE_ME
                </span>
                <a class="value" href="mailto:dylantasat11@gmail.com">
                    dylantasat11@gmail.com
                </a>
            </div>
        </div>
    </div>
    <div class="contact-image-container" style.transform="translateX({{animations.contactImageTranslation}}%)">
        <div class="contact-image-shadow" style.transform="translate({{animations.contactShadowOpening}}px, {{animations.contactShadowOpening}}px)"></div>
        <img srcset="
            /assets/images/contact-image/contact-image.png 1x,
            /assets/images/contact-image/contact-image@2x.png 2x
        " alt="" class="contact-image" style.transform="translate(-{{animations.contactShadowOpening}}px, -{{animations.contactShadowOpening}}px)">
    </div>
</div>
