import { trigger, transition, style, animate } from '@angular/animations';
import { UtilsService } from './services/utils.service';
import { AnimationsService } from './services/animations.service';
import { Animations } from './models/animations.model';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('backgroundPicture', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('1s', style({ opacity: '*' }))
            ])
        ])
    ]
})
export class AppComponent {
    title = 'dylan-webpage';

    availableLanguages = [
        { name: 'Es', value: 'es' },
        { name: 'En', value: 'en' }
    ];

    socialNetworks = [
        { name: 'facebook', link: 'https://www.facebook.com/profile.php?id=100011398391909' },
        { name: 'instagram', link: 'https://www.instagram.com/dylantasat/' },
        { name: 'linkedin', link: 'https://www.linkedin.com/in/dylan-tasat-ab8528169/' },
        { name: 'twitter', link: 'https://twitter.com/dylantasat' }
    ];

    animations: Animations = {};

    menuOpen = false;

    get currentPage() {
        return this.animationsService.currentPage;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        const scroll = window.scrollY;
        this.animations = this.animationsService.getAnimations(scroll);
    }

    constructor(
        private translateService: TranslateService,
        private animationsService: AnimationsService,
        public utilsService: UtilsService
    ) {
        this.onScroll();
        document.body.setAttribute('style', `height: ${this.getScrollHeight()}px;`);
    }

    setLanguage(newLanguage: string) {
        this.translateService.use(newLanguage);
    }

    getCurrentLanguage() {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }

    getScrollHeight() {
        return Object.values(this.animationsService.animationStatesDefinitions)
            .map(definition => definition.duration)
            .reduce((a, b) => a + b, 0) +
            window.innerHeight -
            400;
    }
}
