import { Project } from './models/color.model';
import { Quote } from './models/quote.model';
import { Color } from './models/project.model';

export const quotes: Quote[] = [
    { text: 'QUOTES.NOWIK', author: 'Ariel Nowik', contact: 'mailto:ariel.nowik@gmail.com' },
    { text: 'QUOTES.COUCHOT', author: 'Lucas Couchot', contact: 'mailto:lucas.couchot@flexia.com.ar' },
    { text: 'QUOTES.NORBERT', author: 'Norberto Ordenavía', contact: 'mailto:norberto.ordenavia@flexia.com.ar' }
];

// El primer proyecto de cada año debe tener "showYear: true".
export const projects: Project[] = [
    { name: 'PROJECTS.OSME.NAME', description: 'PROJECTS.OSME.DESCRIPTION', year: 2022, showYear: true },
    { name: 'PROJECTS.LINK.NAME', description: 'PROJECTS.LINK.DESCRIPTION', year: 2022 },
    { name: 'PROJECTS.GARANTOR_REMAX.NAME', description: 'PROJECTS.GARANTOR_REMAX.DESCRIPTION', year: 2021, showYear: true },
    { name: 'PROJECTS.FRONTEND_COURSE.NAME', description: 'PROJECTS.FRONTEND_COURSE.DESCRIPTION', year: 2021 },
    { name: 'PROJECTS.WIN2WIN.NAME', description: 'PROJECTS.WIN2WIN.DESCRIPTION', year: 2021 },
    { name: 'PROJECTS.FLEXNODE.NAME', description: 'PROJECTS.FLEXNODE.DESCRIPTION', link: 'https://bo.flexnode.com.ar/', year: 2021 },
    { name: 'PROJECTS.DYLANS_WEB.NAME', description: 'PROJECTS.DYLANS_WEB.DESCRIPTION', link: 'https://gitlab.com/dtasat/dylan-webpage', year: 2020, showYear: true },
    { name: 'PROJECTS.FLEXIA_LANDING.NAME', description: 'PROJECTS.FLEXIA_LANDING.DESCRIPTION', link: 'https://flexia.com.ar/', year: 2020 },
    { name: 'PROJECTS.FLEXIA_SOCIAL.NAME', description: 'PROJECTS.FLEXIA_SOCIAL.DESCRIPTION', year: 2020 },
    { name: 'PROJECTS.FLEXBURO.NAME', description: 'PROJECTS.FLEXBURO.DESCRIPTION', year: 2020 },
    { name: 'PROJECTS.IAJA.NAME', description: 'PROJECTS.IAJA.DESCRIPTION', link: 'https://iaja.com.ar/', year: 2020 },
    { name: 'PROJECTS.GARANTOR.NAME', description: 'PROJECTS.GARANTOR.DESCRIPTION', link: 'https://garantor.com.ar/', year: 2019, showYear: true },
    { name: 'PROJECTS.REMOTE_CONTROL_CAR.NAME', description: 'PROJECTS.REMOTE_CONTROL_CAR.DESCRIPTION', link: 'https://github.com/elgrandt/remote-control-car', year: 2019 },
    { name: 'PROJECTS.FELICITY.NAME', description: 'PROJECTS.FELICITY.DESCRIPTION', year: 2019 },
    { name: 'PROJECTS.FLEX_FIDEICOMISO.NAME', description: 'PROJECTS.FLEX_FIDEICOMISO.DESCRIPTION', link: 'https://flexportalpagos.com.ar/', year: 2019 },
    { name: 'PROJECTS.FLEXIA_START.NAME', description: 'PROJECTS.FLEXIA_START.DESCRIPTION', year: 2019, color: new Color(23, 149, 223) },
    { name: 'PROJECTS.CALIFICA_PROFESORES.NAME', description: 'PROJECTS.CALIFICA_PROFESORES.DESCRIPTION', link: 'https://github.com/elgrandt/CalificaProfesores-iOS', year: 2018, showYear: true },
    { name: 'PROJECTS.FLIMPER_END.NAME', description: 'PROJECTS.FLIMPER_END.DESCRIPTION', year: 2018, color: new Color(23, 149, 223) },
    { name: 'PROJECTS.FLIMPER_APP.NAME', description: 'PROJECTS.FLIMPER_APP.DESCRIPTION', year: 2017, showYear: true },
    { name: 'PROJECTS.OGL.NAME', description: 'PROJECTS.OGL.DESCRIPTION', link: 'https://github.com/elgrandt/OGL', year: 2017 },
    { name: 'PROJECTS.FLIMPER_START.NAME', description: 'PROJECTS.FLIMPER_START.DESCRIPTION', year: 2017, color: new Color(23, 149, 223) },
    { name: 'PROJECTS.SHOOTER.NAME', description: 'PROJECTS.SHOOTER.DESCRIPTION', link: 'https://github.com/elgrandt/ShooterInc', year: 2016, showYear: true },
    { name: 'PROJECTS.POWER_VIDEO.NAME', description: 'PROJECTS.POWER_VIDEO.DESCRIPTION', link: 'https://github.com/elgrandt/PowerVideo', year: 2015, showYear: true },
    { name: 'PROJECTS.ROULETTE.NAME', description: 'PROJECTS.ROULETTE.DESCRIPTION', link: 'https://github.com/elgrandt/Ruleta-Rusa', year: 2015 },
    { name: 'PROJECTS.HEAD_SOCCER_NETWORK.NAME', description: 'PROJECTS.HEAD_SOCCER_NETWORK.DESCRIPTION', link: 'https://github.com/newtonis/head_soccer_network', year: 2015 },
    { name: 'PROJECTS.3D_PLOTTER.NAME', description: 'PROJECTS.3D_PLOTTER.DESCRIPTION', link: 'https://github.com/elgrandt/Graficador-3D', year: 2014, showYear: true },
    { name: 'PROJECTS.TUX_WORLD_4.NAME', description: 'PROJECTS.TUX_WORLD_4.DESCRIPTION', link: 'https://github.com/elgrandt/TuxWorld-4', year: 2014 },
    { name: 'PROJECTS.TUX_WORLD_3.NAME', description: 'PROJECTS.TUX_WORLD_3.DESCRIPTION', link: 'https://github.com/elgrandt/TuxWorld-3', year: 2014 },
    { name: 'PROJECTS.TANKS.NAME', description: 'PROJECTS.TANKS.DESCRIPTION', link: 'https://github.com/elgrandt/Tanks', year: 2014 },
    { name: 'PROJECTS.PONG.NAME', description: 'PROJECTS.PONG.DESCRIPTION', link: 'https://github.com/elgrandt/Pong-Network', year: 2014 },
    { name: 'PROJECTS.TUX_WORLD_2.NAME', description: 'PROJECTS.TUX_WORLD_2.DESCRIPTION', link: 'https://github.com/elgrandt/TuxWorld-2', year: 2013, showYear: true },
];
