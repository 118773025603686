
export class Color {
    constructor(public r: number, public g: number, public b: number, public a?: number) {}

    toString() {
        if (this.a) {
            return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
        } else {
            return `rgb(${this.r}, ${this.g}, ${this.b})`;
        }
    }
}
