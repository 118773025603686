import { AnimationsService } from './../../services/animations.service';
import { UtilsService } from './../../services/utils.service';
import { Animations } from './../../models/animations.model';
import { projects } from './../../constants';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

    @Input()
    active: boolean;

    @Input()
    animations: Animations;

    projects = projects;

    get currentProject() {
        return this.animationsService.currentProject;
    }

    @ViewChild('timeline')
    set timelineElement(element: ElementRef<HTMLElement>) {
        this.animationsService.timelineElement = element;
    }

    @ViewChild('projectsOverview')
    set projectsOverviewElement(element: ElementRef<HTMLElement>) {
        this.animationsService.projectsOverviewElement = element;
    }

    @ViewChild('projectsOverviewContainer')
    set projectsOverviewContainerElement(element: ElementRef<HTMLElement>) {
        this.animationsService.projectsOverviewContainerElement = element;
    }

    constructor(
        public utilsService: UtilsService,
        private animationsService: AnimationsService
    ) { }

    ngOnInit(): void {
    }

    getProjectOpacity(index: number) {
        if (this.utilsService.isMobile()) {
            if (index === this.currentProject)
                return this.animations.projectsOpacity;
            if (index === this.currentProject - 1 || index === this.currentProject + 1)
                return this.animations.projectsOpacity * .4;
            return 0;
        } else {
            if (index === this.currentProject)
                return this.animations.projectsOpacity;
            if (index === this.currentProject + 1)
                return this.animations.projectsOpacity * .6;
            if (index === this.currentProject + 2)
                return this.animations.projectsOpacity * .4;
            return 0;
        }
    }

    getProjectBackgroundColor(index: number) {
        if (index === this.currentProject) {
            if (projects[index].color) {
                return projects[index].color.toString();
            }
            return 'white';
        }
        return 'transparent';
    }
}
