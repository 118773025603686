
<div class="home" [ngClass]="{ 'active-page': active }">
    <div class="page-title" @title>
        <span class="first-line" style.transform="translateX({{animations.titleFirstLineTranslation}}%)">
            <span class="colored" translate>PAGE_TITLE.LINE_1_1</span>&nbsp;<span translate>PAGE_TITLE.LINE_1_2</span>
        </span>
        <span class="second-line" translate style.transform="translateX({{animations.titleSecondLineTranslation}}%)">
            PAGE_TITLE.LINE_2
        </span>
        <span class="third-line" translate style.transform="translateX({{animations.titleThirdLineTranslation}}%)">
            PAGE_TITLE.LINE_3
        </span>
    </div>
    <span class="page-description" translate @description style.transform="translateX({{animations.descriptionTranslation}}%)">
        PAGE_DESCRIPTION
    </span>
</div>
