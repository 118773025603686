import { AnimationsService } from './../../services/animations.service';
import { quotes } from './../../constants';
import { Animations } from './../../models/animations.model';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

    @Input()
    active: boolean;

    @Input()
    animations: Animations;

    quotes = quotes;

    @ViewChild('characeristics')
    set characteristicsElement(element: ElementRef<HTMLElement>) {
        this.animationsService.characteristicsElement = element;
    }

    constructor(
        private animationsService: AnimationsService
    ) { }

    ngOnInit(): void {
    }

}
