import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    constructor(
        private breakpointObserver: BreakpointObserver
    ) { }

    isMobile() {
        return this.breakpointObserver.isMatched('(max-aspect-ratio: 1/1), (max-width: 700px)');
    }
}
