<div class="menu-overlay" [ngClass]="{ open: open }" (click)="closed.emit()">
    <div class="menu" (click)="$event.stopPropagation()">
        <div class="left">
            <svg-icon src="/assets/images/close.svg" alt="close" class="clickable-text close-button" (click)="closed.emit()"></svg-icon>
        </div>
        <div class="right">
            <span class="name">
                Dylan Tasat
            </span>
            <span class="button" *ngFor="let button of buttons" (click)="button.onClick()" translate>
                {{ button.label }}
            </span>
        </div>
    </div>
</div>
