<div class="quote" id="quote-{{index}}" style.transform="translateY({{translation}}px)" [style.opacity]="opacity">
    <span class="quote-icon">"</span>
    <div class="quote-content">
        <p class="quote-text">
            {{ quote.text | translate }}
        </p>
        <span class="quote-author">
            {{ quote.author }}
        </span>
        <a class="link contact-reference" [href]="quote.contact" translate>
            CONTACT_REFERENCE
        </a>
    </div>
</div>
