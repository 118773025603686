import { Animations } from './../../models/animations.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    @Input()
    active: boolean;

    @Input()
    animations: Animations;

    constructor() { }

    ngOnInit(): void {
    }

}
