<div class="projects" [ngClass]="{ 'active-page': active }">
    <h1 class="projects-title" translate style.transform="translateY({{animations.projectsTitleTranslation}}%)">
        PROJECTS_TITLE
    </h1>
    <div class="projects-view">
        <div class="timeline-container" [style.opacity]="animations.timelineOpacity">
            <div class="timeline" #timeline style.transform="translateY({{animations.timelineTranslation}}px)">
                <div class="timeline-item" *ngFor="let project of projects">
                    <span class="year">
                        {{ project.showYear ? project.year : '' }}
                    </span>
                    <div class="line"></div>
                </div>
            </div>
            <div class="current-indicator"></div>
        </div>
        <div class="projects-overview-container" #projectsOverviewContainer>
            <div class="projects-overview" #projectsOverview style.transform="translate{{ utilsService.isMobile() ? 'Y' : 'X' }}({{animations.projectsTranslation}}px)">
                <div
                    class="project"
                    *ngFor="let project of projects; let i = index"
                    [ngClass]="{ primary: i === currentProject }"
                    [style.opacity]="getProjectOpacity(i)"
                    id="project-{{i}}"
                >
                    <div class="project-background" [style.backgroundColor]="getProjectBackgroundColor(i)"></div>
                    <h3 class="title" translate>
                        {{ project.name }}
                    </h3>
                    <span class="description" translate>
                        {{ project.description }}
                    </span>
                    <div class="spacing"></div>
                    <a [href]="project.link" target="_blank" class="link" *ngIf="project.link" translate>
                        PROJECT_VIEW_MORE
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
