import { AnimationsService } from './../../services/animations.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    @Input()
    open: boolean;

    @Output()
    closed = new EventEmitter<void>();

    buttons = [
        { label: 'MENU_BUTTONS.HOME', onClick: () => this.scrollToState('home') },
        { label: 'MENU_BUTTONS.ABOUT_ME', onClick: () => this.scrollToState('about', 680) },
        { label: 'MENU_BUTTONS.PROJECTS', onClick: () => this.scrollToState('projects') },
        { label: 'MENU_BUTTONS.CONTACT', onClick: () => this.scrollToState('contact') },
    ];

    constructor(
        private animationsService: AnimationsService
    ) { }

    ngOnInit(): void {
    }

    scrollToState(state: string, offset: number = 0) {
        let scroll = 0;
        for (const [currentState, config] of Object.entries(this.animationsService.animationStatesDefinitions)) {
            if (currentState === state) break;
            scroll += config.duration;
        }
        window.scrollTo(0, scroll + offset);
        this.closed.emit();
    }
}
